import { useState } from "react";
import {
  Card,
  Grid,
  Typography,
  CardContent,
  CardActions,
  Button,
  Divider,
  Chip
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import Anime from 'react-anime';
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedinIn, faGithub } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"

import me from "../images/me.png";
import react from "../images/react.png";
import css from "../images/css.png";
import typescript from "../images/typescript.png";
import jest from "../images/jest.png";
import python from "../images/python.png";
import vim from "../images/vim.png";
import git from "../images/git.png";
import qmk from "../images/qmk.png";
import cs from "../images/cs.png";

const StyledCard = styled(Card)`
  max-width: 380px;
  margin: 30px;
  padding-top: 30px;
`
const StyledImgContainer = styled.div`
  margin-left: 45px;
  margin-bottom: -70px;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  position: relative;
  background-color: #272727;
  overflow: hidden;
`
const StyledButton = styled(Button)`
  color: ${props => props.textColor};
  font-size: 0.75em;
  text-transform: capitalize;

  .MuiButton-iconSizeSmall > *:first-child {
    font-size: 19px;
    margin-bottom: 2px;
    color: #e4746e;
  }
`
const StyledImgA = styled.img`
  display: inline;
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
`
const StyledImgB = styled.img`
  display: inline;
  position: absolute;
  top: ${props => props.top ? props.top : 0};
  left: ${props => props.left ? props.left : "100px"};
  width: 100px;
  height: 100px;
`

const useStyles = makeStyles({
  h5: {
    fontFamily: "Montserrat, Helvetica, Sans-serif",
    fontWeight: 700
  },
  small: {
    fontFamily: "Roboto, Helvetica, Sans-serif",
    color: "#333",
    display: "block"
  },
  chip: {
    margin: "5px 5px 0 0",
    color: "#616161",
    "&:hover": {
      backgroundColor: "#fafae6"
    }
  }
})

const Content = props => {

  const [getState, setState] = useState({
    imgAUrl: me,
    imgBUrl: me,
    hasMoved: false,
    direction: 0,
    leave: false
  });

  let completed = false;

  const classes = useStyles(props);

  const tools = {
    "React":{
      "img": react
    },
    "HTML/CSS":{
      "img": css
    },
    "TypeScript":{
      "img": typescript
    },
    "Jest":{
      "img": jest
    },
    "Python":{
      "img": python
    },
    "Vim":{
      "img": vim
    },
    "Git":{
      "img": git
    },
    "QMK":{
      "img": qmk
    },
    "Adobe CS":{
      "img": cs
    }
  };

  const links = {
    "github":{
      "link": "https://github.com/mtdvs",
      "icon": faGithub
    },
    "linkedIn":{
      "link": "https://www.linkedin.com/in/matt-davis-9b225130/",
      "icon": faLinkedinIn
    },
    "email":{
      "link": "mailto:me@mtdvs.com",
      "icon": faEnvelope
    }
  };

  const makeCoords = (e) => {
    const {
      offsetHeight,
      offsetWidth,
      offsetTop,
      offsetLeft
    } = e.target;

    const { pageX, pageY } = e;

    return { pageX, pageY, offsetTop, offsetLeft, offsetWidth, offsetHeight }
  }

  const getDirection = ({
    pageX,
    pageY,
    offsetTop,
    offsetLeft,
    offsetWidth,
    offsetHeight
  }) => {

    const w = offsetWidth;
    const h = offsetHeight;
    const x = (pageX - offsetLeft - (w/2)) * (w > h ? (h/w) : 1);
    const y = (pageY - offsetTop  - (h/2)) * (h > w ? (w/h) : 1);

    return Math.round((((Math.atan2(y,x) * (180/Math.PI)) + 180)) / 90 + 3) % 4;
  }

  const mouseEnter = (e, img) => {

    const coords = makeCoords(e);
    const direction = getDirection(coords);

    if(!getState.hasMoved) {
      setState(prevState => ({...prevState, imgBUrl: img, direction: direction, leave: false, hasMoved: true}));
    } else {
      setState(prevState => ({...prevState, imgBUrl: img, direction: direction, leave: false}));
    }
  }

  const mouseLeave = (e, img) => {

    const coords = makeCoords(e);
    const direction = getDirection(coords);

    setState(prevState => ({...prevState, imgBUrl: me, direction: direction, leave: true}))
  }

  const renderTools = () => {
    return Object.entries(tools).map(([tool, {img}], index) => {
      return (
        <Chip
          variant="outlined"
          color="primary"
          className={classes.chip}
          size="small"
          label={tool}
          key={index}
          onMouseEnter={(e) => mouseEnter(e, img)}
          onMouseLeave={(e) => mouseLeave(e, img)}
        />
      )
    })
  };

  const renderLinks = () => {
    return Object.entries(links).map(([name, {link,icon}], index) => {
      return (
        <StyledButton size="small" href={link} startIcon={<FontAwesomeIcon icon={icon} />}>{name}</StyledButton>
      )
    })
  };

  const renderDirectionSchema = (direction) => {

    const { leave } = getState;

    switch(direction) {
      case 0:
        if (leave) return { x: [0, 0], y: [0, 100], top: "-100px", left: "0" }
        return { x: [0, 0], y: [0, -100], top: "100px", left: "0" }
      case 1:
        if (leave) return { x: [0, -100], y: [0, 0], top: "0", left: "100px" }
        return { x: [0, 100], y: [0, 0], top: "0", left: "-100px" }
      case 2:
        if (leave) return { x: [0, 0], y: [0, -100], top: "100px", left: "0" }
        return { x: [0, 0], y: [0, 100], top: "-100px", left: "0" }
      case 3:
        if (leave) return { x: [0, 100], y: [0, 0], top: "0", left: "-100px" }
        return { x: [0, -100], y: [0, 0], top: "0", left: "100px" }
      default:
        return { x: [0, 0], y: [0, 100], top: "100px", left: "0" }
    }
  }
  const makeDelay = () => {
    if (getState.imgBUrl === me) {
      return 500
    }  else if (completed) {
      return 0
    } else {
      return 50
    }
  }

  const renderStyledImg = () => {
    const { x, y, top, left } = renderDirectionSchema(getState.direction);

    if(!getState.hasMoved) {
      return (
        <>
          <StyledImgA src={me} alt="" />
        </>
      )
    } else if (getState.imgAUrl === getState.imgBUrl) {
      return (
        <StyledImgA src={getState.imgAUrl} alt="" />
      )
    } else {
      return (
        <Anime
          key={x}
          easing="easeOutQuad"
          duration={150}
          direction="normal"
          delay={makeDelay()}
          loop={false}
          translateX={x}
          translateY={y}
          complete={() => {
            if (getState.imgBUrl === me) {
              completed = false;
            } else {
              completed = true;
            }
            setState(prevState => ({...prevState, imgAUrl: prevState.imgBUrl}))
          }}
        >
          <>
            <StyledImgA src={getState.imgAUrl} alt="" />
            <StyledImgB src={getState.imgBUrl} top={top} left={left} alt="" />
          </>
        </Anime>
      )
    }
  }

  return (
    <Grid container justify="center" alignItems="center" style={{height:"100vh"}}>
      <Grid item>
        <StyledImgContainer alt="">
          {renderStyledImg()}
        </StyledImgContainer>
        <StyledCard>
          <CardContent>
            <Typography variant="h5" component="h1" className={classes.h5} gutterBottom>
              Hi, I'm Matt.
            </Typography>
            <Typography
              variant="h6"
              component="h1"
              className={classes.h5}
              style={{
                lineHeight: 1.334,
                marginBottom: 30
              }}
              gutterBottom
            >
              I'm a frontend developer currently based out of South Florida.
            </Typography>
            <Typography variant="body1" className={classes.small} gutterBottom>
              <small>Current toolkit:</small>
            </Typography>
            {renderTools()}
          </CardContent>
          <Divider />
          <CardActions>
            {renderLinks()}
          </CardActions>
        </StyledCard>
      </Grid>
    </Grid>
  )
}

export default Content;
