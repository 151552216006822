import Content from "./components/Content.js";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

function App() {

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#abd5e0',
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <Content />
    </ThemeProvider>
  );
}

export default App;
